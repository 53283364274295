.download__wrapper {
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-gray-50);
}

.download__wrapper .download__content {
    border: 1px solid var(--color-primary-50);
    padding: 1.75rem;
    background-color: var(--color-white);
}

.download__wrapper .download__content .icon__download {
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.625rem;
    color: unset;
}