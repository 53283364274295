#cmp_credit_activity_list {
    flex-direction: column;
}

#cmp_credit_activity_list .category_wrapper {
    min-height: 5.375rem;
    padding: 1rem;
    gap: 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-50);
    border: 0.0625rem dashed var(--color-gray-300);
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
}

#cmp_credit_activity_list .category_header {
    width: 100%;
    gap: 1rem;
}

#cmp_credit_activity_list .category_title {
    flex-grow: 1;
}

#cmp_credit_activity_list .category_date {
    width: 9rem;
}

#cmp_credit_activity_list .category_summary {
    width: 5.5rem;
}

#cmp_credit_activity_list .item_wrapper {
    border-top: 1px solid var(--color-gray-300);
    padding-top: 0.75rem;
    width: 100%;
    gap: 1rem;
    min-height: 3.125rem;
}

#cmp_credit_activity_list .item_description {
    flex-grow: 1;
}

#cmp_credit_activity_list .react-datepicker-wrapper {
    width: 9rem;
}

#cmp_credit_activity_list .item_credits input {
    width: 5.5rem;
    text-align: right;
}

