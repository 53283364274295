#btn_refresh {
    border-width: 1px;
    margin-left: 0.5rem;
}

.icon__refresh {
    width: 1rem;
    height: 1rem;
    color: var(--color-primary-500);
}
