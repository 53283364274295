/* Modal Components */
.cmp_modal.cmp__docviewer {
    width: 75rem;
}

.cmp__docviewer .modal__header {
    flex-direction: column;
}

.cmp_modal.cmp__docviewer .modal__content__container {
  min-height: 75%;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
}

.cmp__docviewer .modal__footer {
  gap: 1rem;
}

.cmp__docviewer .error__wrapper {
  justify-content: center;
}

.cmp__docviewer .document__controls {
  margin-top: 0.25rem;
  align-self: center;
}

.cmp__docviewer button.ui.button.btn__pdf_search {
  border-radius: 0;
  border-right: 0;
}

.cmp__docviewer .text--sm-medium {
  align-self: center;
  margin: 0 0.25rem 0 1rem;
}

.cmp__docviewer .page-control {
  position: relative;
  z-index: 1;
  top: 78%;
  align-self: center;
}

.cmp__docviewer .page-label {
  background: var(--color-white);
  border: 0.063rem solid var(--color-gray-300);
  border-right: none;
  color: var(--color-gray-500);
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem 0 0.75rem;
}

.cmp__docviewer .page-arrow {
  align-self: center;
  margin-left: auto;
  height: 0.75rem;
  width: 0.475rem;
}

/* Image Components */

#image_preview {
  margin: 0;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  height: 60vh;
}

#image_preview img {
  transition: transform .2s;
  margin-top:3vh;
}

#imgPreviewContainer {
  height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: block;
}

.contain {
  object-fit: contain;
}

/* PDF Components */

.pdf-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.pdf-viewer {
  flex: 1;
  transition: width 400ms ease-in-out;
  width: 100%;
}

.pdf_preview {
  height: 60vh;
  overflow: auto;
  align-items: center;
}

.pdf_document {
  display: flex;
  flex-direction: column;
}

.pdf_page {
  border: 0.063rem solid darkgrey;
  box-shadow: 0.313rem 0.313rem 0.313rem 0.063rem #ccc;
  border-radius: 0.313rem;
  margin: auto;
}

.rpv-core__text-layer,
.rpv-core__canvas-layer,
.rpv-core__page-layer,
.rpv-core__inner-page,
.rpv-core__viewer {
  box-sizing: border-box;
  display: block !important;
  user-select: text;
  text-align: left;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

/* PDF Search Popover */
input.rpv-core__textbox {
  padding: 0 0.75rem;
}

#rpv-core__popover-body-inner-search .rpv-search__popover {
  display: block !important;
}

.rpv-page-navigation__current-page-input {
  align-self: center;
  margin-right: 0.75rem !important;
  width: 3.5rem !important;
}

.rpv-core__viewer.rpv-core__viewer--light > div > div {
  display: block !important;
  width: 100%;
}

.rpv-core__viewer.rpv-core__viewer--light#core__inner-container {
  flex: 1;
  flex-grow: 1
}

.rpv-core__arrow.rpv-core__arrow--bl.rpv-core__popover-body-arrow {
  transform: translate(50%,-50%) rotate(225deg);
  top: unset;
  bottom: 5.488rem;
}

.rpv-page-navigation__current-page-input {
  margin: 0;
  width: 1.563rem;
}

button.rpv-core__button,
button.rpv-core__minimal-button,
.rpv-core__minimal-button.rpv-core__minimal-button--disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 0.125rem solid var(--color-primary-500);
    border-radius: 0.375rem;
    box-sizing: border-box;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 2.375rem;
    background-color: var(--color-white);
    color: var(--color-primary-500);
}

.rpv-core__textbox {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 19.5rem;
  height: 2.375rem;
  align-items: center;
  margin-top: 0.25rem;
  padding: 0.5rem 0.8125rem;
  border: 0.063rem solid var(--color-gray-300);
  border-radius: 0.375rem;
  box-sizing: border-box;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-gray-900);
}

.rpv-search__popover-label {
  display: none !important;
}

/* PDF Bookmarks */

.pdf-bookmarks {
  border-right: none;
  transition: width 400ms ease-in-out;
  width: 0%;
  white-space: nowrap;
  overflow: auto;

  box-sizing: border-box;
  display: block;
  user-select: text;
  text-align: left;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.pdf-bookmarks.open {
    border-right: 0.063rem solid rgba(0, 0, 0, 0.3);
    width: 20%;
}

.rpv-bookmark__container, .rpv-bookmark__list {
    overflow: visible !important;
}

.rpv-bookmark__empty {
  margin-left: 0.5rem;
}

.pdf-viewer.bookmarks-open {
  width: 80%;
}

/*  MEDIA QUERIES =================================================================================================== */

/*@media only screen and (max-width: 77rem) {*/
/*    .cmp_modal.cmp__docviewer {*/
/*        width: 90%;*/
/*    }*/
/*}*/

@media only screen and (max-width: 40rem) {
  #rpv-core__popover-body-search.rpv-core__popover-body {
    left: 25% !important;
  }
  .rpv-core__arrow.rpv-core__arrow--bl.rpv-core__popover-body-arrow {
    transform: translate(50%,-50%) rotate(225deg);
    top: 0;
    left: 45%;
  }
}
