.tablecontrols_search_input div.ui.input > input {
    width: 12.5rem;
    margin-left: 0.5rem;
    background-color: white;
}

.tablecontrols_search_input .svg.icon {
    width: 1.1rem !important;
    height: 1.1rem !important;
}

@media only screen and (max-width: 70rem) {
    .tablecontrols_search_input div.ui.input > input {
        margin-left: 0;
    }
}