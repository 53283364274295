#mdl_input_descriptor {
    width: 56.25rem;
}

#mdl_input_descriptor .field__feedback{
    max-width: 19.125rem;
}

#select_credential_method {
    flex-direction: column;
    border-bottom: 1px solid var(--color-gray-300);
    margin-bottom: 2.5rem;
}

#select_credential_method .detailsgroup {
    flex-direction: column;
}

#select_credential_method .detailsgroup_wrapper {
    gap: 1rem;
}

#select_credential_method .detailsgroup_wrapper .detailsgroup__value {
    width: 100%;
}

#select_credential_method .btn_toggle_credential_selection_method_wrapper {
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1rem;
}

#schema_url .detailsgroup__value {
    width: 100%;
    margin-right: 1rem;
}

#schema_url .button {
    margin-top: 0.875rem;
}

#select_credential_attributes {
    flex-direction: column;
    margin-bottom: 1.5rem;
}

#select_credential_attributes .detailsgroup_wrapper {
    gap: 1rem;
}

#select_credential_attributes .detailsgroup_wrapper .detailsgroup__value {
    max-width: 14rem;
}

#select_accepted_statuses {
    margin-top: 1rem;
}

#mdl_input_descriptor .delete_icon {
    align-self: start;
    margin-top: 0.875rem;
    justify-content: flex-end;
}

#mdl_input_descriptor .delete_icon svg {
    width: 0.875rem;
    height: 1.125rem;
    cursor: pointer;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 53.125rem) {

    #select_credential_method .detailsgroup_wrapper,
    #select_credential_attributes .detailsgroup_wrapper,
    #select_accepted_statuses {
        flex-direction: column;
        gap: unset;
    }

    #select_credential_attributes .detailsgroup_wrapper .detailsgroup__value {
        max-width: 100%;
    }

    #select_accepted_statuses {
        gap: 1rem;
    }

    #select_credential_attributes .detailsgroup_wrapper {
        gap: 0rem;
        margin-top: 1.5rem;
    }

    #select_credential_attributes .detailsgroup {
        padding: 0.5rem 0;
    }

    #mdl_input_descriptor .delete_icon {
        margin-top: 0;
    }

    #select_credential_method .btn_toggle_credential_selection_method_wrapper {
        display: inline;
    }
}