
/*  COLORS ========================================================================================================== */

:root {
  --color-white: #FFFFFF;

  --color-primary-900: #00111B;
  --color-primary-800: #002236;
  --color-primary-700: #003451;
  --color-primary-600: #00456C;
  --color-primary-500: #005687;
  --color-primary-400: #31769D;
  --color-primary-300: #6296B4;
  --color-primary-200: #C3D5E1;
  --color-primary-100: #DCE5EC;
  --color-primary-50: #E8EDF1;

  --color-secondary-900: #192126;
  --color-secondary-800: #31434B;
  --color-secondary-700: #4A6471;
  --color-secondary-600: #628696;
  --color-secondary-500: #7BA7BC;
  --color-secondary-400: #93B7C8;
  --color-secondary-300: #ABC6D4;
  --color-secondary-200: #C4D6DF;
  --color-secondary-100: #DCE5EB;
  --color-secondary-50: #E8EDF1;

  --color-tertiary-900: #311C09;
  --color-tertiary-800: #623812;
  --color-tertiary-700: #94551C;
  --color-tertiary-600: #C57125;
  --color-tertiary-500: #F68D2E;
  --color-tertiary-400: #F6A256;
  --color-tertiary-300: #F5B77E;
  --color-tertiary-200: #F5CBA7;
  --color-tertiary-100: #F4E0CF;
  --color-tertiary-50: #F4EBE3;

  --color-gray-900: #111827;
  --color-gray-800: #1F2937;
  --color-gray-700: #374151;
  --color-gray-600: #4B5563;
  --color-gray-500: #6B7280;
  --color-gray-400: #9CA3AF;
  --color-gray-300: #D1D5DB;
  --color-gray-200: #E5E7EB;
  --color-gray-100: #F3F4F6;
  --color-gray-50: #F9FAFB;

  --color-red-900: #7F1D1D;
  --color-red-800: #991B1B;
  --color-red-700: #B91C1C;
  --color-red-600: #DC2626;
  --color-red-500: #EF4444;
  --color-red-400: #F87171;
  --color-red-300: #FCA5A5;
  --color-red-200: #FECACA;
  --color-red-100: #FEE2E2;
  --color-red-50: #FEF2F2;

  --color-orange-900: #7C2D12;
  --color-orange-800: #9A3412;
  --color-orange-700: #C2410C;
  --color-orange-600: #EA580C;
  --color-orange-500: #F97316;
  --color-orange-400: #FB923C;
  --color-orange-300: #FDBA74;
  --color-orange-200: #FED7AA;
  --color-orange-100: #FFEDD5;
  --color-orange-50: #FFF7ED;

  --color-yellow-900: #78350F;
  --color-yellow-800: #92400E;
  --color-yellow-700: #B45309;
  --color-yellow-600: #D97706;
  --color-yellow-500: #F59E0B;
  --color-yellow-400: #FBBF24;
  --color-yellow-300: #FCD34D;
  --color-yellow-200: #FDE68A;
  --color-yellow-100: #FEF3C7;
  --color-yellow-50: #FFFBEB;

  --color-green-900: #064E3B;
  --color-green-800: #065F46;
  --color-green-700: #047857;
  --color-green-600: #059669;
  --color-green-500: #10B981;
  --color-green-400: #34D399;
  --color-green-300: #6EE7B7;
  --color-green-200: #A7F3D0;
  --color-green-100: #D1FAE5;
  --color-green-50: #ECFDF5;

  --color-blue-900: #1E3A8A;
  --color-blue-800: #1E40AF;
  --color-blue-700: #1D4ED8;
  --color-blue-600: #2563EB;
  --color-blue-500: #3B82F6;
  --color-blue-400: #60A5FA;
  --color-blue-300: #93C5FD;
  --color-blue-200: #BFDBFE;
  --color-blue-100: #DBEAFE;
  --color-blue-50: #EFF6FF;

  --color-indigo-900: #312E81;
  --color-indigo-800: #3730A3;
  --color-indigo-700: #4338CA;
  --color-indigo-600: #4F46E5;
  --color-indigo-500: #6366F1;
  --color-indigo-400: #818CF8;
  --color-indigo-300: #A5B4FC;
  --color-indigo-200: #C7D2FE;
  --color-indigo-100: #E0E7FF;
  --color-indigo-50: #EEF2FF;

  --color-purple-900: #4C1D95;
  --color-purple-800: #5B21B6;
  --color-purple-700: #6D28D9;
  --color-purple-600: #7C3AED;
  --color-purple-500: #8B5CF6;
  --color-purple-400: #A78BFA;
  --color-purple-300: #C4B5FD;
  --color-purple-200: #DDD6FE;
  --color-purple-100: #EDE9FE;
  --color-purple-50: #F5F3FF;

  --color-pink-900: #831843;
  --color-pink-800: #9D174D;
  --color-pink-700: #BE185D;
  --color-pink-600: #DB2777;
  --color-pink-500: #EC4899;
  --color-pink-400: #F472B6;
  --color-pink-300: #F9A8D4;
  --color-pink-200: #FBCFE8;
  --color-pink-100: #FCE7F3;
  --color-pink-50: #FDF2F8;

}



/*  TEXT ============================================================================================================ */

.display--2xl-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.display--2xl-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.display--2xl-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.display--xl-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.display--xl-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.display--xl-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.display--lg-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
}

.display--lg-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 3rem;
  line-height: 3rem;
}

.display--lg-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
}

.display--md-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.display--md-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.display--md-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.display--sm-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.display--sm-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.display--sm-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.display--xs-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
}

.display--xs-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.display--xs-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.text--xl-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text--xl-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text--xl-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text--lg-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text--lg-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text--lg-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text--md-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text--md-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text--md-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text--sm-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text--sm-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text--sm-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text--xs-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text--xs-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text--xs-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
}

.text--anchor {
  cursor: pointer;
  color: var(--color-primary-500) !important;
  text-decoration: underline !important;
}


/*  BORDER RADIUS & SHADOWS ========================================================================================= */

.rounded-none {
  border-radius: 0;
}

.rounded-xs {
  border-radius: 0.125rem;
}

.rounded-sm {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 50%;
}

.shadow-xs {
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
}

.shadow-sm {
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 0.625rem 1rem -0.1875rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 1.25rem 1.5625rem -0.3125rem rgba(0, 0, 0, 0.1), 0 0.625rem 0.625rem -0.3125rem rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 1.5625rem 3.125rem -0.75rem rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 0.125rem 0.25rem rgba(0, 0, 0, 0.06);
}


/*  MARGINS / PADDING =============================================================================================== */

.margin--sm {
  margin: 0.25rem;
}

.margin--md {
  margin: 0.5rem;
}

.margin--lg {
  margin: 0.75rem;
}

.padding--sm {
  padding: 0.25rem;
}

.padding--md {
  padding: 0.5rem;
}

.padding--lg {
  padding: 0.75rem;
}


/*  ICONS =========================================================================================================== */

.icon--sm {
  width: 1rem;
  height: 1rem;
}


/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 31.25rem) {

  .display--sm-medium {
    font-size: 1.5rem;
    line-height: 2rem;
  }

}
