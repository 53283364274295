#form_workerprofile_info .detailsgroup__value.name {
    flex-direction: row;
    justify-content: space-between;
    max-width: 19.125rem;
}

#form_workerprofile_info .detailsgroup__value .name_wrapper {
    flex-direction: column;
    max-width: 48%;
}

@media only screen and (max-width: 31.25rem) {
    #form_workerprofile_info .detailsgroup__value.name {
        max-width: 100%;
    }
}