#crd_verification_credits .verification_credits_wrapper {
    margin-left: 2rem;
}

#crd_verification_credits .credits_pie_chart {
    height: 10rem;
    max-width: 20rem;
}

#crd_verification_credits .credits_pie_chart svg {
    width: 10rem;
}

#crd_verification_credits .chart_labels {
    flex-direction: column;
    align-self: center;
    margin-left: 2rem;
}

#crd_verification_credits .label_wrapper {
    align-items: center;
}

#crd_verification_credits .label_rectangle {
    height: 0.5rem;
    width: 1.3125rem;
    margin-right: 0.35rem;
}

#crd_verification_credits .label_rectangle.available {
    background-color: var(--color-blue-500);
}

#crd_verification_credits .label_rectangle.allocated {
    background-color: var(--color-yellow-500);
}

#crd_verification_credits .label_rectangle.used {
    background-color: var(--color-red-500);
}

#crd_verification_credits .credit_display_wrapper {
    align-items: center;
    margin-left: 3rem;
    gap: 1.5rem;
}

#crd_verification_credits .credit_display {
    height: 4rem;
    width: 10rem;
    flex-direction: column;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-gray-300);
}

#crd_verification_credits .credit_number {
    font-size: 1.875rem !important;
}

#crd_verification_credits .warning_message,
#crd_verification_credits .warning_message a {
    display: inline;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 90rem) {
    #crd_verification_credits .verification_credits_wrapper {
        flex-direction: column;
    }
    #crd_verification_credits .credit_display_wrapper {
        margin-left: 0;
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 40rem) {
    #crd_verification_credits .verification_credits_wrapper {
        margin: 0;
    }
    #crd_verification_credits .credit_display_wrapper {
        gap: 0.5rem;
    }
    #crd_verification_credits .credits_pie_chart {
        justify-content: center;
    }
    #crd_verification_credits .credit_display {
        padding: 1.5rem 1rem;
    }
}