#vpviewer__wrapper {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
}

#vpviewer__wrapper .vpviewer__header {
    border-bottom: 1px solid var(--color-gray-200);
    flex-direction: column;
    margin-bottom: 1rem;
    padding-bottom: 1.75rem;
}

#vpviewer__wrapper .vpviewer__item_wrapper {
    flex-direction: column;
}

#vpviewer__wrapper .vpviewer__item {
    justify-content: space-between;
    cursor: pointer;
}

#vpviewer__wrapper .vpviewer__item_label {
    flex-direction: column;
}

#vpviewer__wrapper .vpviewer__item_wrapper ~ .vpviewer__item_wrapper {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-gray-200);
}

#vpviewer__wrapper .json__wrapper {
    border: 1px solid var(--color-gray-200);
    border-radius: 0.375rem;
    margin-top: 1rem;
    overflow-x: scroll;
    padding: 1rem;
}

#vpviewer__wrapper .json__wrapper div {
    display: block;
}
