.breadcrump_wrapper {
    background-color: var(--color-blue-100);
    margin: -1.563rem -1.875rem 1.5rem -1.875rem;
    padding: 1.563rem 1.875rem 0 1.875rem;
}

#breadcrumb li {
    list-style-type: none;
}

#breadcrumb ol {
    display: flex;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

#breadcrumb button {
    border: none;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    align-items: start;
    color: var(--color-primary-500);
    height: auto;
    border-radius: 0;
}

@media only screen and (max-width: 53.125rem) {

    #breadcrumb,
    .breadcrump_wrapper {
      display: none;
    }
}