.table__options__search .btn_export {
    border-width: 1px;
    margin-left: 0.5rem;
    padding: 0.5rem 0.75rem
}

button.btn_export.not_ready {
    opacity: 0.5;
}

button.btn_export.loading {
    background-color: transparent !important;
}

button.btn_export.loading:after {
    border-color: #2ea3a6 transparent transparent !important;
}

.icon__export {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-primary-500);
}
