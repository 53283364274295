
#mdl_onboarding {
    max-width: 31.25rem;
    width: 31.25rem;
    min-width: unset;
}

#mdl_onboarding .display--xs-bold {
    margin-bottom: 1rem;
}

#mdl_onboarding .modal__footer {
    border: none;
    background-color: transparent;
    flex-direction: row;
    padding: 2rem 0 0 0;
    align-self: center;
}

#mdl_onboarding .progress__indicator__wrapper {
    width: 100%;
    padding-top: 1rem;
    justify-content: center;
}

#mdl_onboarding .progress__indicator {
    width: 1.625rem;
    height: 0.3125rem;
    border-radius: 0.15625rem;
    margin: 0 0.25rem;
    background-color: var(--color-gray-300);
}

#mdl_onboarding .progress__indicator.active {
    background-color: var(--color-gray-900);
}

#mdl_onboarding img {
    width: 100%;
    min-height: 250px;
    aspect-ratio: 2/1;
}

@media only screen and (max-width: 34.375rem) {

    #mdl_onboarding {
        max-width: 90%;
    }

    #mdl_onboarding img {
        min-height: 225px;
    }

}