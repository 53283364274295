#crd_theme_design .crd_body {
    gap: 1rem;
}

#crd_theme_design .crd_form {
    display: flex;
    width: 23rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    border: 1px solid var(--color-gray-300);
    background: var(--color-white);
    box-shadow: 0 0.25rem 3.75rem 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
}

#crd_theme_design .crd_form .color_picker_wrapper {
    width: 19.125rem;
    display: flex;
    justify-content: space-between;
}

#crd_theme_design .crd_form .color_picker_wrapper > div {
    max-width: 46%;
}

#crd_theme_design .crd_form input[type='color'] {
    width: 100%;
}

#crd_theme_design .crd_form .inputs_row > div:not(.ui.buttons) {
    flex-direction: column;
    flex: 1;
}

#crd_theme_design .crd_form .field input {
    width: 19.125rem !important;
}

#crd_theme_design .crd_form .ui.buttons {
    width: 19.125rem;
    margin-bottom: 1rem;
}

#crd_theme_design .crd_form .ui.buttons button {
    width: 50%;
}

#crd_theme_design .crd_form .message {
    margin: 0;
}

#crd_theme_design .crd_form .spacer {
    width: 100%;
    border-bottom: 0.0625rem solid var(--color-gray-200);
}

#crd_theme_design .card_view {
    flex-direction: column;
    gap: 0.5rem;
    min-width: 24.31rem;
    width: 24.31rem;
}

#crd_theme_design .entra_card {
    margin-top: 1.5rem;
}

#crd_theme_design a {
    display: inline;
}

#crd_theme_design .org_profile_link_wrapper {
    display: inline;
    margin-top: 0.25rem;
    width: 19.125rem;
}

#crd_theme_design .logo__description {
    width: 19.125rem;
    margin-bottom: 0.25rem;
}

.credentialcard__entra {
    padding: 1rem !important;
}

@media only screen and (max-width: 80rem) {

    #crd_theme_design .crd_body .crd_form {
        width: unset;
        max-width: 31.25rem;
    }

    #crd_theme_design .crd_body {
        flex-direction: column;
        gap: 1rem;
    }

    #crd_theme_design .cmp_verification_template {
        min-width: unset;
    }
}

@media only screen and (max-width: 73.3125rem) {

    #crd_theme_design .crd_body.digitalcard {
        flex-direction: column;
        gap: 1rem;
    }

}

@media only screen and (max-width: 36.25rem) {

    #crd_theme_design .cmp_verification_template {
        padding: 0;
        max-width: 31.25rem;
        border: none;
    }

    #crd_theme_design .cmp_verification_template .template__body {
        border-radius: 0;
        box-shadow: none;
    }
}

@media only screen and (max-width: 66.75rem) {

    #crd_theme_design .crd_body.digitalcard .crd_form {
        max-width: 25rem;
        width: unset;
    }

    #crd_theme_design .crd_body.digitalcard {
        flex-direction: column;
        gap: 0.5rem;
    }

}

@media only screen and (max-width: 29.125rem) {

    #crd_theme_design .card_view {
        min-width: unset;
        width: 100%;
    }

}