#cmp_oid4vc_credential_offer {
    min-width: unset !important;
    max-width: 500px !important;
}

#cmp_oid4vc_credential_offer .modal__header > div {
    width: 100%;
}

#cmp_oid4vc_credential_offer .modal__content__text {
    flex-direction: column;
}

#cmp_oid4vc_credential_offer .qr_svg {
    margin: 0 auto 2rem;
}

#cmp_oid4vc_credential_offer .verificationcode_label {
    margin: 0 auto !important;
}

#cmp_oid4vc_credential_offer .verificationcode_value {
    margin: 0 auto 2rem;
    box-sizing: border-box;
    background: #E5E7EB;
    border: 1px solid #9CA3AF;
    border-radius: 6px;
    padding: 8px 12px;
    letter-spacing: 1.75rem !important;
    text-indent:-1.75rem;;
    direction:rtl;
}