.icon__verified_identity {
    color: var(--color-green-500);
    height: 1.125rem;
}

#cmp_verified_identity_display button.checkmark_verified_identity,
#cmp_verified_identity_display .icon__verified_identity {
    padding: 0;
    align-self: center;
    width: 1.25rem;
}

#cmp_verified_identity_display .btn_individual_name {
    padding: 0;
    width: auto;
}

td:has(#cmp_verified_identity_display) {
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    height: auto;
}