.cmp_vc_authenticator {
    min-width: unset !important;
    max-width: 500px !important;
}

.qr_svg {
    margin: 0 auto 2rem;
}

.btn_authenticator {
    margin: 0 auto !important;
    width: 220px;
}

.verificationcode_label {
    margin: 0 auto !important;
}

.verificationcode_value {
    margin: 0 auto 2rem;
    box-sizing: border-box;
    background: #E5E7EB;
    border: 1px solid #9CA3AF;
    border-radius: 6px;
    padding: 8px 12px;
    letter-spacing: 1.75rem !important;
    text-indent:-1.75rem;;
    direction:rtl;
}