#mdl__cmp_identity_verification .modal__content {
    gap: 1.5rem
}

#mdl__cmp_identity_verification .form-icon {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem 0.25rem 0 0;
}

#mdl__cmp_identity_verification .primary-icon {
    align-self: center;
    width: 6rem;
    height: 6rem;
}

#mdl__cmp_identity_verification .primary-icon.gray {
    color: var(--color-gray-400);
}

#mdl__cmp_identity_verification img {
    width: 7.5rem;
    height: 1.125rem;
}