
.table__controls__rowsperpage {
    display: flex;
    align-items: center;
    color: var(--color-gray-700);
}

.table__controls__rowsperpage .field {
    margin-bottom: 0;
    margin-left:0.25rem;
}

.table__controls__rowsperpage .field .dropdown {
    width: 4.75rem;
}