.mdl_override__container, .modal__content__container {
    flex-direction: column;
}

.mdl_override__container .mdl_override__body {
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.mdl_override__container textarea {
    width: 31.25rem;
    max-width: calc(100vw - 3.5rem);
    height: 8.125rem;
}

.mdl_override__container .mdl_override__body > div:last-child {
    flex-direction: column;
    align-items: center;
}

.mdl_override__container .mdl_override__body > div:last-child > div:last-child {
    width: 100%;
}

.mdl_override__container .mdl_override__body > div:last-child > div:last-child > div:last-child {
    margin-left: auto;
}

.mdl_override__container div.field.error {
    border-color: var(--color-red-600);
}