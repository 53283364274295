
#content_document.content__wrapper {
    flex-direction: column;
    padding-top: 1rem;
}

#content_document .content__description {
    margin-bottom: 1rem;
}

#content_document #subgroup_expiry .detailsgroup__subgroup__content {
    gap: 1rem;
}

#content_document #subgroup_expiry .detailsgroup__subgroup__content__item {
    width: calc(50% - 0.375rem);
}

#content_document #subgroup_expiry .detailsgroup__subgroup__content__item .dropdown {
    width: 100%;
}
