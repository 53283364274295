#crd__verification {
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

#crd__verification  .preloader__wrapper {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

#crd__verification > div.verification__wrapper {
    margin: 1.5rem;
    max-width: 25rem;
    min-width: 17rem;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 2rem 2.5rem;
    align-self: center;
}

#crd__verification .verification__logo {
    margin-top: 0.5rem;
}

#crd__verification .verification__title {
    max-width: 23rem;
    align-self: center;
    text-align: center;
}

#crd__verification .verification__description {
    margin-top: 1rem;
    align-self: center;
}

#crd__verification .verification__logo img {
    max-width: 14.375rem;
    max-height: 5rem;
}

#crd__verification div.qrcode_body {
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    gap: 0.5rem;
}

#crd__verification div.qrcode_wrapper {
    position: relative;
}

#crd__verification div.qrcode_expired {
    position: absolute;
    width: 12.5rem;
    height: 12.5rem;
    background-color: var(--color-white);
}

#crd__verification div.qrcode_expired div.svg {
    opacity: 0.1;
}

#crd__verification div.qrcode_expiry {
    display: inline;
    text-align: center;
}

#crd__verification div.qrcode_expiry span {
    margin-left: 0.5rem;
}

#crd__verification div.qrcode_body button {
    margin-top: 1rem;
    width: 100%;
}

#crd__verification > div.verification__wrapper > canvas.qrcode {
    margin-top: 1.5rem;
    align-self: center;
    width: 12.5rem;
    height: 12.5rem;
}

#crd__verification > div.verification__wrapper > div.qrcode_used {
    margin: 1.5rem 0;
    align-self: center;
    width: 12.5rem;
    height: 12.5rem;
    flex-direction: column;
    border-radius: 0.5rem;
    background-color: var(--color-gray-100);
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

#crd__verification > div.verification__wrapper > div.qrcode_used #btn_refresh {
    margin-left: 0;
    padding: 0.56rem 1.06rem;
}

#crd__verification > div.verification__wrapper div.verification_result {
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 1.5rem;
    align-items: center;
}

#crd__verification > div.verification__wrapper div.verification_result_body {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    border-radius: 0.5rem;
}

#crd__verification > div.verification__wrapper div.verification_result_body.fulfilled { 
    border-color: var(--color-green-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body.fail { 
    border-color: var(--color-red-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body.error { 
    border-color: var(--color-orange-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body div.header {
    padding: 1rem 1.5rem 0.75rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.header .icon {
    width: 100%;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.header .icon::before,
#crd__verification > div.verification__wrapper div.verification_result_body div.header .icon::after {
    content: '';
    width: calc(50% - 1.75rem);
    border-bottom: 0.0625rem solid white;
    height: 0.75rem;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.header .icon svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem 0.5rem 1rem;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.header .text--lg-bold {
    color: var(--color-white);
}

#crd__verification > div.verification__wrapper div.verification_result_body.fulfilled div.header {
    background-color: var(--color-green-600);
    color: var(--color-green-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body.fail div.header {
    background-color: var(--color-red-600);
    color: var(--color-red-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body.error div.header {
    background-color: var(--color-orange-600);
    color: var(--color-orange-600);
}

#crd__verification > div.verification__wrapper div.verification_result_body div.details {
    padding: 1.25rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    background: #FFF;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.details div.result { 
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    padding-bottom: 0.625rem;
}

#crd__verification > div.verification__wrapper div.verification_result_body div.details div.result:last-child { 
    border-bottom: unset;
    padding-bottom: unset;
}

#crd__verification .message.error {
    margin: 1.5rem;
}

#crd__verification > div.verification__wrapper div.verification_result button {
    width: 100%;
    margin-left: 0;
}
