
#credential_details_security .tbl__permissions {
    flex-direction: column;
}

#credential_details_security .tbl__permissions .table__wrapper {
    border: 1px solid var(--color-gray-200);
    border-bottom: none;
}

#credential_details_security .tbl__permissions button {
    height: 2.375rem;
}

#credential_details_security .tbl__permissions__legend {
    margin-top: 1rem;
    flex-wrap: wrap;
}

#credential_details_security .tbl__permissions__legend__item {
    width: calc(50% - 2rem);
    padding-right: 2rem;
}

@media only screen and (max-width: 80.625rem) {

    #credential_details_security .tbl__permissions__legend__item {
        width: 100%;
    }

}
