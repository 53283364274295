.card__individual_prequalifiers .detailsgroup .prequalifiers_icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
}

.card__individual_prequalifiers .detailsgroup .prequalifier_text {
    flex-direction: column;
}

.card__individual_prequalifiers .detailsgroup .prequalifier_text .badge {
    margin: 1rem 0;
}

.card__individual_prequalifiers .detailsgroup .prequalifier_text .prequalifier_credential_detail {
    margin-bottom: 1rem;
}

.card__individual_prequalifiers .detailsgroup .prequalifier_text .prequalifier_change_credential {
    text-decoration: underline;
    color: var(--color-primary-500);
    cursor: pointer;
}

.card__individual_prequalifiers .detailsgroup .prequalifier_text .prequalifier_credential_detail:last-of-type {
    margin-bottom: 0;
}

.card__individual_prequalifiers .detailsgroup .prequalifiers_actionbutton {
    margin-left: auto;
    margin-bottom: auto;
}

@media only screen and (max-width: 55rem) {
    .card__individual_prequalifiers .detailsgroup .prequalifiers_actionbutton {
        margin-top: 1.5rem;
        margin-left: 1.625rem;
    }
}