#individual .individual{
    display: grid;
    flex-direction: column;
    width: 22.188rem;
    height: 10.938rem;
    background: var(--color-primary-400);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 1.125rem;
}

#individual .individual__details {
    flex-direction: column;
    align-items: center;
    margin: 1.313rem 0 1.313rem 0;
    color: var(--color-white);
}

#individual .individual__org__logo img {
    max-height: 3rem;
    max-width: 100%;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 26.25rem) {
    #individual .individual {
        width: auto;
    }
}