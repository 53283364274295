.profile_wrapper {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 0;
    flex-direction: column;
    width: 100%;
}

.profile_content {
    padding-bottom: 25px;
    flex-direction: column;
}

.profile_list {
    flex-direction: column;
}

.profile_list > div {
    padding-top: 30px;
    align-self: center;
    width: 90%;
}

.profile_header {
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    border-bottom: 1px solid #ebebeb;
}

.profile_header_left {
    display: flex;
    align-items: center;
}

.profile_header_title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
}

.profile_header_subtitle {
    font-size: 14px;
    color: #7a7d7b;
    margin-top: 3px;
}

.profile_header_info {
    list-style: none;
    padding: 0;
    margin: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.profile_header_info li {
    margin-right: 30px;
}

.profile_header_info_th {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

.profile_header_detail_item {
    font-size: 14px;
    padding: 3px 0;
}

.profile_header_detail_item .icon {
    font-size: 16px;
    margin-right: 10px;
}

.profile_header_detail_item > img {
    width:18px;
    height: 18px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.profile_section {
    margin-top: 25px;
    padding: 0 20px;
}

.profile_section_header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.profile_section_body {
    border: 1px solid #ebebeb;
    background-color: #fafbfb;
}

.profile_section_body .tablewrapper {
    margin-bottom: 0;
    flex-direction: column;
}

.profile_section_body .tablewrapper .ui.table {
    margin-top: 0 !important;
}

.profile_section_body .tablewrapper .ui.table thead th {
    text-transform: uppercase;
}

.profile_section_body .tablewrapper .ui.table tr td {
    height: 31px;
}

.profile_section_body .tablewrapper .ui.table tr td img {
    width: 18px;
    height: 18px;
}

.profile_verification {
    display: flex;
}

.profile_verification img {
    margin-right: 3px;
}

.profile_onsite_status {
    display: flex;
    align-items: center;
}

.profile_onsite_status img {
    margin-right: 4px;
}

@media (max-width: 767px) {
    .profile_header_title {
        font-size: 24px;
    }

    .profile_header_title {
        font-size: 18px;
    }

    .profile_header {
        padding: 15px 10px;
    }

    .profile_header_info li {
        margin-left: 10px;
    }

    .profile_section {
        padding: 0 10px;
    }
}
