#crd_template_activity .option_button_wrapper {
    flex-direction: row;
    padding-bottom: 1.25rem;
    gap: 13px;
}

#crd_template_activity .datepicker_wrapper {
    flex-direction: column;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 78.125rem) {

    #crd_template_activity .option_button_wrapper {
        flex-direction: column; 
        padding-bottom: 0;
    }
    
    
    #crd_template_activity .datepicker_wrapper {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}