.modal__add_requirement .detailsgroup {
    border-top: none;
    align-items: unset;
}

.modal__add_requirement .detailsgroup .detailsgroup__label {
    margin-top: 0.25rem;
}

.modal__add_requirement .detailsgroup div.dropdown {
    width: 19.5rem;
    max-width: calc(100vw - 9rem);
}

.modal__add_requirement .detailsgroup div.no_preapproved_message {
    max-width: 30.75rem;
}
