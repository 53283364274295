
.transcript_print {
    visibility: hidden;
    position: absolute;
    z-index: 9999;
}

@media print {

    * {
        visibility: hidden;
        margin:0;
        padding:0;
        box-sizing: border-box;
    }

    body {
        min-height: unset !important;
    }

    html, body {
        overflow: hidden;
    }

    #crd_templink,
    #crd_email {
        display: none;
    }

    .transcript_print {
        visibility: visible;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        justify-content: center;
    }

    .transcript_print * {
        visibility: visible;
    }

    .transcript_print .cmp_transcript {
        margin-top: -10%;
        scale: 70%;
        print-color-adjust: exact;
    }

    .transcript_print .table__wrapper {
        overflow: visible;
    }

}