.cmp_confirmation__wrapper {
    width: 35rem !important;
    min-width: unset !important;
    max-width: 35rem !important;
    height: auto !important;
    display: flex !important;
    flex-direction: row !important;
    padding: 1.5rem !important;
}

.cmp_confirmation__wrapper.visible.transition {
    display: flex !important;
}

.cmp_confirmation__icon {
    margin-right: 1rem;
}

.cmp_confirmation__text {
    flex-direction: column;
}

.cmp_confirmation__icon .svg {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--color-yellow-400);
}

.cmp_confirmation__title {
    margin-bottom: 0.5rem;
}

.cmp_confirmation__message {
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 1rem;
}

@media only screen and (max-width: 77rem) {
    .cmp_confirmation__wrapper {
        width: 85% !important;
    }
}

@media only screen and (max-width: 41rem) {
    .cmp_confirmation__wrapper {
        width: 80% !important;
    }
}

@media only screen and (max-width: 31.25rem) {
    .cmp_confirmation__wrapper {
        width: 75% !important;
    }
}