
#mdl_enablevc {
    max-width: 33.75rem;
    min-width: unset;
}

#mdl_enablevc .content__description {
    flex-direction: column;
    max-width: 100%;
}

#mdl_enablevc .content__description div {
    padding-bottom: 1rem;
}

#mdl_enablevc .detailsgroup {
    flex-direction: column;
    width: 100%;
}

#mdl_enablevc .detailsgroup .field,
#mdl_enablevc .detailsgroup input {
    width: 100%;
}

