.modal__add_document {
    position: relative;
}

.modal__add_document .modal__content__container > div {
    width: 100%;
    border-top: none;
}

.modal__add_document .modal__content__container div.detailsgroup__label_document {
    align-self: flex-start;
    margin-top: 0.5rem;
}