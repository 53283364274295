

#mdl__addcredential .issuer_credential_row > div:last-of-type {
    flex-direction: column;
    flex-grow: 1;
}

#mdl__addcredential .issuer_credential_row button {
    align-self: flex-start;
}

#mdl__addcredential .addcredential__prequalifiers_wrapper {
    flex-direction: column;
    width: calc(100% - 11.25rem);
}

#mdl__addcredential .addcredential__prequalifiers_wrapper > div:not(:first-of-type) {
    margin-top: 0.5rem;
}

#mdl__addcredential .addcredential__prequalifiers_wrapper > div:not(:last-of-type) {
    margin-bottom: 0.5rem;
}

#mdl__addcredential .addcredential__prequalifiers_wrapper .addcredential__prequalifiers_item .prequalifiers_icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
}

#mdl__addcredential .text_wrapper{
    display: block;
}

#mdl__addcredential .text__property {
    display: block;
    max-width: 30rem;
    margin-bottom: 1rem;
}

#mdl__addcredential .text__property:last-child {
    margin-bottom: 0;
}

#mdl__addcredential .link {
    display: inline;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

#mdl__addcredential .custom_fields {
    flex-direction: column;
}

#mdl__addcredential .custom_fields img {
    max-width: 25rem;
}


@media only screen and (max-width: 55rem) {

    #mdl__addcredential .addcredential__prequalifiers_wrapper {
        width: unset;
    }

    #mdl__addcredential .issuer_credential_row > div {
        width: 100%;
    }

    #mdl__addcredential .detailsgroup__label {
        width: 11.25rem !important;
    }
}

@media only screen and (max-width: 30rem) {

    #mdl__addcredential .custom_fields img {
        max-width: 20rem;
    }

}