#form_cr8credential_step2 #subgroup_expiry .detailsgroup__subgroup__content {
    flex-direction: row;
    width: 100%;
    gap: 0.75rem;
}

#form_cr8credential_step2 #subgroup_expiry .detailsgroup__subgroup__content__item {
    width: calc(50% - 0.375rem);
}

#form_cr8credential_step2 #subgroup_expiry .detailsgroup__subgroup__content__item .dropdown {
    width: 100%;
}

#id__fieldlabel {
    width: 15.625rem;
}

#id__fieldlabel input {
    width: 15.625rem;
    color: var(--color-gray-900) !important;
}

#field__credit_value input {
    width: 6.5rem;
}

