#mdl_survey {
    display: flex !important;
}

#mdl_survey.survey_loaded {
    height: calc(100vh - 1rem);
}

#mdl_survey .modal__content {
    flex: 1;
    padding: 0;
}