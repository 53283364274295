
.TEMP_DEV_NOTE {
    color: var(--color-red-600);
    width: 100%;
    border-bottom: 1px dashed var(--color-red-600);
    margin-top: 1rem;
    padding-left: 1rem;
}

#mdl_scanqr {
    max-width: 28rem;
    min-width: unset;
}

#mdl_scanqr .content__description {
    align-self: center;
}

.scanframe {
    width: 15.625rem;
    height: 15.625rem;
    align-self: center;
    margin: 0.5rem 0 1.5rem 0;
    background-color: var(--color-gray-100);
}
#mdl_scanqr .detailsgroup {
    flex-direction: column;
    width: 100%;
}

#mdl_scanqr .detailsgroup .field,
#mdl_scanqr .detailsgroup input {
    width: 100%;
}

#mdl_scanqr .validate__wrapper {
    flex-direction: row;
    align-items: start;
}

#mdl_scanqr .validate__wrapper input {
    margin-right: 0.5rem;
}

#mdl_scanqr .validate__wrapper button {
    margin-top: 0.875rem;
}

#mdl_scanqr .code__wrapper {
    flex-direction: row;
    align-self: center;
}

#mdl_scanqr .code__wrapper input {
    width: 2.5rem;
    text-align: center;
}

#mdl_scanqr .code__wrapper .detailsgroup {
    width: 4rem;
}

#mdl_scanqr .code__wrapper .detailsgroup:last-of-type {
    width: 2.5rem;
}


#mdl_scanqr .credentials__wrapper {
    min-height: unset;
    margin-top: 1.5rem;
}

#mdl_scanqr .credentials__item {
    width: 100%;
    border-top: 1px solid var(--color-gray-300);
    padding-top: 0.75rem;
}

#mdl_scanqr .credentials__item:first-of-type {
    border-top: none;
    padding-top: 0;
}

#mdl_scanqr .credentials__item .credentials__item_text {
    flex-direction: column;
}
