#mdl_requirement_add_prequalifier > div.message {
    margin: 1.5rem;
}

#mdl_requirement_add_prequalifier .option_container {
    margin-top: 0.5rem;
    padding: 0.5rem;
    flex-direction: column;
    border: 0.0625rem solid var(--color-gray-200);
    border-radius: 0.5rem;
    gap: 0.5rem;
}