
.mdl_certificate_preview .modal__header .modal__header__left .buttons {
    margin-top: 0.75rem;
}

.mdl_certificate_preview .tab__wrapper {
    margin: 1.5rem;
}

.mdl_certificate_preview .error__wrapper {
    justify-content: center;
  }
