
.question__wrapper {
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-gray-200);
}

.question__wrapper .text--sm-medium {
    margin-bottom: 0.75rem;
}

.question__wrapper:first-of-type {
    padding-top: 0;
}

.question__wrapper:last-of-type {
    border-bottom: none;
}

.question__onsite {
    border-top: 1px solid var(--color-gray-200);
}

.icon__result {
    width: 5rem;
    height: 5rem;
}

.dailycheck__result {
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}


/*  MEDIA QUERIES =================================================================================================== */

/*@media only screen and (max-width: 50rem) {*/
/*    #cmp_dailycheck .modal__footer {*/
/*        height: 8rem;*/
/*    }*/
/*  }*/