#profile .profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 22.188rem;
    height: 10.938rem;
    background: var(--color-primary-400);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
}

#profile .profile__details {
    flex-direction: column;
    align-items: center;
    margin: 1.313rem 0 1.313rem 0;
    color: var(--color-white);
}

#profile .profile .text--xl-bold {
    text-align: center;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 26.25rem) {
    #profile .profile {
        width: auto;
    }
}