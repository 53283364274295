#mdl_qrcode {
    min-width: unset !important;
    width: 23.125rem;
}

#mdl_qrcode .modal__content {
    align-items: center;
}

#mdl_qrcode .header__wrapper {
    width: 100%;
    word-break: break-word;
}