#form_profile_orglogos .detailsgroup__subgroup__content__item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#form_profile_orglogos .detailsgroup__subgroup__content__item img {
    max-width: 6rem;
    max-height: 4rem;
}

#form_profile_orglogos .detailsgroup__subgroup__description {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-gray-200);
    margin: 0;
}

#form_profile_orglogos .detailsgroup__subgroup {
    padding: 2rem;
    border: 0.0625rem dashed var(--color-gray-300);
    max-width: 40rem;
    margin-left: 0;
}

#form_profile_orglogos .select_radio__container {
    border: none;
    box-shadow: none;
    padding: 0;
    height: auto;
    width: auto;
}

#form_profile_orglogos .select_radio__container .ui.radio.checkbox {
    align-self: center;
}

#form_profile_orglogos .select_radio__item:last-of-type {
    padding-bottom: 0;
}

#form_profile_orglogos  .select_radio__item__twoline {
    width: 100%;
}

#form_profile_orglogos .detailsgroup__subgroup__content {
    width: 100%;
}

#form_profile_orglogos .icon__delete__table.disabled {
    color: var(--color-gray-400);
    cursor: unset;
}

#form_profile_orglogos .btn_delete_logo {
    margin-left: auto;
}