#crd__password {
    display: flex;
    flex-direction: column;
}

#crd__password .input {
    width: 19.125rem;
}

#crd__password .verification {
    padding-top: 0;
    border: none;
    margin-bottom: 0.5rem;
}

#crd__password .verification img {
    margin-right: 0.5rem;
}

#crd__password .verification .detailsgroup__value {
    flex-direction: column;
}

#crd__password .verification .text--sm-regular {
    margin-top: 0.5rem;
}

#crd__password .checkmark--gray-300 {
    width: 1rem;
    height: 1rem;
    color: var(--color-gray-300);
    margin-right: 0.5rem;
    align-self: center;
}

#crd__password .error .text--sm-regular {
    color: var(--color-red-600);
    margin-top: 0;
}


@media only screen and (max-width: 29rem) {

    #crd__password .input {
        width: 100%;
    }
}