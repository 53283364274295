
#create_credential__access .tbl__permissions,
#form_cr8credential_step3 .tbl__permissions {
    flex-direction: column;
}

#create_credential__access .tbl__permissions .table__wrapper,
#form_cr8credential_step3 .tbl__permissions .table__wrapper {
    border: 1px solid var(--color-gray-200);
    border-bottom: none;
}

#create_credential__access .tbl__permissions button {
    height: 2.375rem;
}

#create_credential__access .tbl__permissions__legend,
#form_cr8credential_step3 .tbl__permissions__legend {
    margin-top: 1rem;
    flex-wrap: wrap;
}

#create_credential__access .tbl__permissions__legend__item,
#form_cr8credential_step3 .tbl__permissions__legend__item {
    width: 50%;
}

@media only screen and (max-width: 80.625rem) {

    #create_credential__access .tbl__permissions__legend__item,
    #form_cr8credential_step3 .tbl__permissions__legend__item {
        width: 100%;
    }

}