#credentialcard {
    flex-direction: column;
    max-width: 22.1875rem;
    min-width: 17rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 0.5rem;
    padding: 1rem;
    min-height: 10.5rem;
}

.card #credentialcard .text--sm-regular {
    color: unset;
}

#credentialcard .credentialcard__status {
    border-radius: 2rem;
    border: 1px solid;
    padding: 0.25rem 0.625rem;
    display: flex;
    flex-direction: row;
    height: 1.25rem;
    margin-top: 1rem;
}

#credentialcard .credentialcard__status--red {
    background-color: var(--color-red-100);
    border-color: var(--color-red-200);
    color: var(--color-red-800);
}

#credentialcard .credentialcard__status--yellow {
    background-color: var(--color-yellow-100);
    border-color: var(--color-yellow-200);
    color: var(--color-yellow-800);
}

#credentialcard .credentialcard__status--blue {
    background-color: var(--color-blue-100);
    border-color: var(--color-blue-200);
    color: var(--color-blue-800);
}

#credentialcard .credentialcard__status--green {
    background-color: var(--color-green-100);
    border-color: var(--color-green-200);
    color: var(--color-green-800);
}

#credentialcard .credentialcard__dates div:first-of-type {
    margin-right: 1rem;
}

#credentialcard .credentialcard__logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 14rem;
}

#credentialcard .credentialcard__logo img {
    max-width: 50%;
    max-height: 8rem;
}

#credentialcard .credentialcard__top__wrapper {
    justify-content: space-between;
}

#credentialcard .credentialcard__entra_issuer {
    height: 100%;
    align-items: flex-end;
}

#credentialcard.credentialcard__entra .credentialcard__top__wrapper {
    align-items: center;
}

.credentialcard__entra__logo img {
    max-height: 1.5rem;
    width: auto;
}

.credentialcard__entra {
    padding: 0.5rem !important;
    min-height: unset !important;
    height: 9.625rem;
}