#notfound {
    height: 100%;
}

#notfound .text--sm-bold {
    font-size: 1rem;
    color: var(--color-tertiary-500);
}

#notfound .text--sm-medium {
    font-size: 1.875rem;
    line-height: 2.188rem;
}

#notfound .email__link {
    display: inline;
    font-size: 1rem;
}

#notfound .email__link a,
#notfound__offline .email__link a {
    display: inline;
}

#notfound__offline {
    height: 100%;
    width: 100%;
    flex-direction: column;
}

#notfound__offline .nav {
    height: 5rem;
    width: 100%;
    margin-bottom: 12.125rem;
    justify-content: center;
}

#notfound__offline .text--sm-bold {
    font-size: 2.25rem;
    color: var(--color-tertiary-500);
}

#notfound__offline .text--sm-medium {
    font-size: 1.875rem;
    line-height: 2.188rem;
}

#notfound__offline .email__link {
    display: inline;
    font-size: 1rem;
}

#notfound__offline .information {
    flex-direction: column;
    max-width: 36rem;
}

#notfound__offline .logo__img {
    margin-left: 14rem;
}

#notfound__offline .content {
    justify-content: center;
}

#notfound__offline .text--sm-bold {
    margin-bottom: 1.5rem;
}

#notfound__offline .text--sm-medium {
    font-size: 3rem;
    line-height: 3.5rem;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 67.5rem) {

    #notfound__offline .notfound404__img {
        display: none;
    }

    #notfound__offline .logo__img {
        margin-left: 0;
    }

    #notfound__offline .nav__header {
        justify-content: center;
    }

}

@media only screen and (max-width: 50rem) {
    #notfound__offline .text--sm-bold {
        font-size: 1.875rem;
    }

    #notfound__offline .text--sm-medium {
        font-size: 1.875rem;
        line-height: 2.188rem;
    }

    #notfound__offline .nav {
        height: 3.75rem;
        margin-bottom: 4rem;
    }
    #notfound__offline .logo__img {
        width: 160px;
    }
    #notfound__offline .information {
        padding-left: 1.5rem;
    }
}

@media only screen and (max-width: 40rem) {

    #notfound__offline .information {
        max-width: 26rem;
    }
}

@media only screen and (max-width: 31.25rem) {

    .offline {
      padding: 0rem !important;
    }
}