.overview__wrapper {
    display: flex;
    flex-flow: wrap;
}

.overview__wrapper .crd__overview {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    width: 22.125rem;
    height: 6.25rem;
    border-radius: 0.5rem;
    margin-right: 1.875rem;
    flex-grow: 0;
    cursor: pointer;
}

.overview__wrapper .crd__overview .card__header {
    margin-bottom: 0.5rem;
    width: 100%;
}

.overview__wrapper .crd__overview .card__header__left {
    width: 100%;
}

.overview__wrapper .crd__overview .overview__details {
    margin-top: 0.75rem;
    align-items: center;
}

.overview__wrapper .crd__overview .svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.4rem;
}

.summary__wrapper {
    border-top: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: row;
    padding: 0.5526rem 0 0.5625rem 0;
    cursor: pointer;
}

.summary__items {
    flex-direction: row;
}

.summary__text {
    width: 11.25rem;
    align-items: center;
}

.summary__item {
    flex-direction: column;
    margin-right: 1.3125rem;
}

.summary__item__value {
    border-radius: 0.25rem;
    padding: 0.3125rem 0.625rem;
    margin-bottom: 0.25rem;
    justify-content: center;
    color: var(--color-white);
}

.summary__item__label {
    justify-content: center;
}

.tertiary.cell__icon__chevron {
    margin-left: auto;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-radius: 0;
}



/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 51.938rem) {
    .overview__wrapper .crd__overview {
        width: 100%;
        padding: 0 0.75rem 0 0.75rem;
        margin-right: 0;
    }

    .summary__items {
        flex-direction: column;
    }

    .summary__text {
        margin-bottom: 0.5rem;
    }
}
