#vc__wrapper {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
}

#vc__wrapper .vc__header {
    border-bottom: 1px solid var(--color-gray-200);
    flex-direction: column;
    margin-bottom: 1rem;
    padding-bottom: 1.75rem;
}

#vc__wrapper .vc__header .credential_status {
    color: #000000;
}

#vc__wrapper .vc__header .icon__form {
    margin-right: 0.5rem;
}

#vc__wrapper .vc__header .detailsgroup {
    padding-bottom: 0;
}

#vc__wrapper .json__wrapper {
    border: 1px solid var(--color-gray-200);
    border-radius: 0.375rem;
    margin-top: 1rem;
    overflow-x: scroll;
    padding: 1rem;
}

#vc__wrapper .json__wrapper div {
    display: block;
}

