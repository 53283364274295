#crd_creddetails_credit_tasks .menu_column {
    width: 1rem;
}

#crd_creddetails_credit_tasks tr.view_parent > td.data_description {
    font-weight: 500;
    font-size: 0.875rem;
}

#crd_creddetails_credit_tasks tr.view_child > td.data_description {
    padding-left: 4rem;
}

#crd_creddetails_credit_tasks tr.edit_child > td.data_description {
    padding-left: 4rem;
}

#crd_creddetails_credit_tasks tr.edit_parent > td.data_description input {
    width: 25rem;
}

#crd_creddetails_credit_tasks tr.edit_child > td.data_description input {
    width: 22.5rem;
}

#crd_creddetails_credit_tasks tr.edit_parent > td.data_credits_required input, #crd_creddetails_credit_tasks tr.edit_child > td.data_credits_required input {
    width: 10rem;
}

#crd_creddetails_credit_tasks .card__footer {
    border-top: none;
}
