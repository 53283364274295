
.dailycheck_optionbuttons {
    flex-direction: row;
    padding-bottom: 1.25rem;
}

#crd_reports_dailycheck .datepicker_wrapper {
    flex-direction: column;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 78.125rem) {

    .dailycheck_optionbuttons {
        flex-direction: column;
        padding-bottom: 0;
    }

    #crd_reports_dailycheck .datepicker_wrapper {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}
