#crd_batch_credits .activity_date_container {
    padding: 0.5rem 1.5rem;
    background-color: var(--color-gray-50);
    border: 0.0625rem solid var(--color-gray-300);
    border-radius: 0.25rem;
}

#crd_batch_credits .activity_date_container div.detailsgroup__label {
    width: unset;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    color: var(--color-gray-900);
    font-size: 1rem;
    line-height: 1.5rem;
}

#crd_batch_credits .activity_date_container > div:last-of-type {
    padding-top: 0.875rem;
    margin-left: auto;
}

#crd_batch_credits #batch_credit_entry th:not(:first-of-type) .table__headercell .table__headercell_text {
    justify-content: flex-end;
}

#crd_batch_credits #batch_credit_entry .table__headercell .table__headercell_text > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

#crd_batch_credits #batch_credit_entry button {
    margin: unset;
    padding: 0;
    height: unset;
    border: 0;
}

#crd_batch_credits #batch_credit_entry .remove_individual_icon {
    width: 0.625rem;
    height: 0.625rem;
}

#crd_batch_credits #batch_credit_entry tbody > tr > td {
    border-right-width: 0.0625rem;
}

#crd_batch_credits #batch_credit_entry tbody > tr > td:first-of-type {
    border-left-width: 0.0625rem;
}

#crd_batch_credits #batch_credit_entry tr > td:not(:first-of-type),
#crd_batch_credits #batch_credit_entry tr > td:not(:first-of-type) .field {
    text-align: right;
    align-items: flex-end;
}

#crd_batch_credits #batch_credit_entry tr.row_no_credit_entry > td {
    background-color: var(--color-gray-200);
    font-weight: 500;
}

#crd_batch_credits #batch_credit_entry input[type="text"] {
    text-align: right;
}

#crd_batch_credits #batch_credit_entry tr.row_total > td {
    padding-right: 2rem;
    color: var(--color-white);
    background-color: var(--color-gray-500);
    font-weight: 500;
    position: sticky;
    bottom: 0;
}

/* inputs */
#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:not(:first-of-type) {
    position: relative;
    padding: 0;
}

#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:has(input:focus-visible) {
    border: 0.1875rem solid var(--color-yellow-400);
}

#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td.active {
    border: 0.1875rem solid var(--color-blue-500);
}

#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:not(:first-of-type) > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:not(:first-of-type) .ui.input,
#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:not(:first-of-type) input {
    width: 100%;
    height: 100%;
}

#crd_batch_credits #batch_credit_entry tr.row_allow_credit_entry > td:not(:first-of-type) input {
    margin-right: 1rem;
    background-color: transparent;
    border: 0;
    outline: 0 !important;
}
/* end inputs */

#crd_batch_credits .card__header__left.footer__btns {
    margin-top: 2rem;
}

@media only screen and (max-width: 55.5rem) {
    #crd_batch_credits .activity_date_container {
        flex-direction: column;
    }

    #crd_batch_credits .activity_date_container > div:last-of-type {
        margin-left: unset;
        padding-bottom: 1rem;
    }
}
