
.displayrows {
  display: flex;
  align-items: center;
  color: var(--color-gray-700);
}

.displayrows .text--sm-bold {
  padding: 0 0.1875rem;
}

