
.pagination {
  display: flex;
  align-items: center;
  padding-top: 0.35rem;
}

.pagination .item {
  width: 2.5rem;
  cursor: pointer;
  text-align: center;
  color: var(--color-primary-500);
  padding-bottom: 0.625rem;
  text-decoration: none;
}

.pagination .item[type = ellipsisItem] {
  color: var(--color-gray-400);
  cursor: default;
  padding-bottom: 0.625rem;
}

.pagination .active {
  cursor: default;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
  color: var(--color-gray-900);
  border-bottom: 2px solid var(--color-gray-900);
}

.pagination .table__controls--mobile .active {
  border: none;
  width: 100%;
}

.pagination .table__controls--mobile {
  align-items: center;
}

.pagination .table__controls--mobile .field {
  margin-bottom: 0;
  margin-left: 0.25rem;
}

.pagination .table__controls--mobile .field .dropdown {
  width: 4.75rem;
}


@media only screen and (max-width: 70rem) {
  .pagination {
    padding-top: 0;
  }
}
