
.method_selector {
    border-bottom: 1px solid var(--color-gray-200);
    margin-bottom: 0.875rem;
    padding-bottom: 0.875rem;
}

.detailsgroup_supressinvite {
    padding: 0;
    margin-top: -0.75rem;
}

.upload_instructions {
    flex-direction: column;
    margin: 1rem 0 1.5rem 0;
}

.upload_instructions p {
    margin: 0;
}

.upload_instructions p:first-of-type {
    margin-bottom: 1rem;
}

.excel_download .svg {
    width: 0.875rem;
    height: 1rem;
    margin-right: 0.25rem;
    margin-top: 0.125rem;
    color: #000000;
}