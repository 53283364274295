

.fileupload__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 30.75rem;
    row-gap: 4px;
    width: 100%;
}

.dropzone__fileupload {
    min-height: 5.375rem;
    padding: 0.5rem;
    gap: 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-50);
    border: 0.0625rem dashed var(--color-gray-300);
    border-radius: 0.375rem;
}

.dropzone__fileupload.error {
    border: 0.0625rem dashed var(--color-red-600);
}

.dropzone__fileupload .button__browse {
    display: inline;
    height: unset;
}

.dropzone__fileupload .icon__upload {
    width: 2rem;
    height: 1.375rem;
    color: var(--color-gray-400);
}

.dropzone__fileupload .selected_file {
    width: 100%;
    padding-top: 1rem;
    border-top: 0.0625rem solid var(--color-gray-200);
    justify-content: space-between;
    align-items: center;
}

.dropzone__fileupload .selected_file .selected_file_name {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 1rem;
}

.dropzone__fileupload .selected_file > div:first-of-type {
    flex-direction: column;
}

.dropzone__fileupload .selected_file > div:first-of-type > div:last-of-type {
    color: var(--color-gray-600)
}

.dropzone__fileupload .selected_file > div:last-of-type {
    width: 0.875rem;
    height: 1.125rem;
    padding-bottom: 0.25rem;
    cursor: pointer;
    color: var(--color-primary-500);
}

.fileupload__instructions {
    width: 100%;
    color: var(--color-gray-500);
}

.fileupload__wrapper .error_row {
    color: var(--color-red-600);
}


/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 55rem) {

    .fileupload__wrapper {
        max-width: 100%;
    }

}