
#crd_enablevc .card__content {
    flex-direction: row;
}

#crd_enablevc .content__description {
    padding-right: 2rem;
}

#crd_enablevc .card__content__bt_nwrapper {
    margin-left: auto;
}

#crd_enablevc button {
    white-space: nowrap;
}


/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 35.5rem) {

    #crd_enablevc .card__content {
        flex-direction: column;
    }

    #crd_enablevc .content__description {
        padding-bottom: 2rem;
    }

    #crd_enablevc .card__content__btn_wrapper {
        margin-left: unset;
    }

}
