.location_report_card {
    background-color: #ffffff;
    border: 1px solid #e5e7e8;
    border-radius: 4px 4px 0 0;
}

.location_report_header_top {
    display: flex;
    align-items: center;
    padding: 16px;
}

.location_report_org_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.location_report_logo {
    margin-left: 0px !important;
    margin-right: 25px;
}

.location_report_logo img {
    max-width: 150px!important;
    max-height: 120px;
}

.location_report_detail {
    flex: 1;
    padding: 0 0 0 9px;
}

.location_report_title {
    display: flex;
    align-items: center;
}

.report_title {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
}

.location_report_title .ui.button.btn_tertiary {
    margin-left: 15px !important;
}

.location_report_info {
    list-style: none;
    padding: 0;
    margin: 12px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.location_report_info li {
    margin-right: 30px;
}

.location_report_info_th {
    font-size: 11px;
    font-weight: 500;
    color: #cfd3d5;
}

.location_qrcode {
    line-height: 1;
}

.location_report_logo {
    margin-left: 25px;
}

.location_report_logo img {
    max-width: 200px;
}

.location_report_card .detailsummary {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed #ebebeb;
    background-color: #fafbfb;
    padding: 0 25px;
    flex-wrap: wrap;
}

.location_report_card .detailsummary_header {
    display: flex;
    flex-direction: column;
    padding: 15px  24px 15px 0px;
    justify-content: center;
}

.location_report_card .detailsummary_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding-right: 4px;
    color: #5c6b70;
}

.location_report_card .detailsummary_subtitle {
    font-size: 13px;
    font-weight: 400;
    color: #969fa2;
}

.location_report_card .detailsummary_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.location_report_card .detail_wrapper {
    padding: 12px 0;
}

.content-card-body {
    padding: 0 25px 25px;
}

.public_location_section {
    margin-top: 25px;
}
.public_location_section_header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.public_location_section_body {
    border: 1px solid #ebebeb;
    background-color: #fafbfb;
}

.public_location_section_body .tablewrapper {
    margin-bottom: 0;
}

.public_location_section_body .tablewrapper .ui.table {
    margin-top: 0 !important;
}

.remove_table_pointer_event tr {
    pointer-events: none !important;
}

.reset_table_pointer .ui.table tr,
.reset_table_pointer .ui.table tr td ,
.reset_table_pointer .ui.table thead th {
  cursor:default !important;
}

.reset_table_pointer .ui.table tbody tr .td_verification {
    cursor:pointer !important;
}

@media (max-width: 1083px) {
    #dailychecksummary.summary_wrapper {
        padding-left: 0;
    }
}

@media (max-width: 1180px) {
    #dailychecksummary.summary_wrapper {
        padding-right: 6px;
    }
    #credentialsummary.summary_wrapper {
        padding: 0 6px;
    }
    #onsitesummary.summary_wrapper {
        padding: 0 6px;
    }
    #credentialsummary .summary_icon,
    #dailychecksummary .summary_icon,
    #onsitesummary .summary_icon {
        margin-right: 10px;
    }

    #credentialsummary .summary_icon img,
    #dailychecksummary .summary_icon img,
    #onsitesummary .summary_icon img {
        width: 20px;
        height: 20px;
    }
    #dailychecksummary .summary_item,
    #onsitesummary .summary_item,
    #credentialsummary .summary_item {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 980px) {
    #dailychecksummary .summary_item,
    #onsitesummary .summary_item,
    #credentialsummary .summary_item {
        margin-right: 8px;
    }
}

@media (max-width: 767px) {
    .content-card-body {
        padding: 0 16px 16px;
    }

    .report_title {
        font-size: 24px;
    }

    .location_report_logo img {
        max-width: 150px!important;
        max-height: 120px;
    }
}

@media (max-width: 575px) {
    .location_report_header_top {
        align-items: flex-start;
    }
    .location_report_title {
        display: block;
    }
    .location_report_title .ui.button.btn_tertiary {
        margin-left: 0 !important;
    }

    .location_report_logo img {
        max-width: 150px!important;
        max-height: 100px!important;
    }
}