#crd_cred_design .crd_body {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    gap: 1rem;
}

#crd_cred_design .crd_form {
    display: flex;
    width: 25rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    border: 1px solid var(--color-gray-300);
    background: var(--color-white);
    box-shadow: 0 0.25rem 3.75rem 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
}

#crd_cred_design .crd_form .inputs_row {
    flex-direction: row;
}

#crd_cred_design .crd_form .inputs_row > div {
    flex-direction: column;
    flex: 1;
}

#crd_cred_design .crd_form .message {
    margin: 0;
}

#crd_cred_design .crd_form .spacer {
    width: 100%;
    border-bottom: 0.0625rem solid var(--color-gray-200);
}

#crd_cred_design .cmp_certificate {
    margin: unset !important;
}

#crd_cred_design .card_view {
    flex-direction: column;
    gap: 0.5rem;
    min-width: 24.31rem;
    width: 24.31rem;
}

#crd_cred_design .entra_card {
    margin-top: 1.5rem;
}

#crd_cred_design a {
    display: inline;
}

#crd_cred_design .org_profile_link_wrapper {
    display: inline;
    margin-top: 0.25rem;
    width: 19.125rem;
}

.credentialcard__entra {
    padding: 1rem !important;
}

@media only screen and (max-width: 84.75rem) {

    #crd_cred_design .crd_body.certificate .crd_form {
        width: unset;
        max-width: 37.625rem;
    }

    #crd_cred_design .crd_body.certificate {
        flex-direction: column;
        gap: 1rem;
    }

    #crd_cred_design .cmp_certificate {
        margin-left: 0 !important;
        min-width: unset;
    }
}

@media only screen and (max-width: 73.3125rem) {

    #crd_cred_design .crd_body.digitalcard {
        flex-direction: column;
        gap: 1rem;
    }

}

@media only screen and (max-width: 70rem) {

    .crd_body.certificate .cmp_certificate {
        border: 1px solid var(--color-gray-300);
    }

}

@media only screen and (max-width: 66.75rem) {

    #crd_cred_design .crd_body.digitalcard .crd_form {
        max-width: 25rem;
        width: unset;
    }

    #crd_cred_design .crd_body.digitalcard {
        flex-direction: column;
        gap: 0.5rem;
    }

}

@media only screen and (max-width: 34.375rem) {

    .cmp_certificate .certificate__body .certificate__body__datagroup {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .cmp_certificate .certificate__details {
        flex-direction: column;
    }

    .cmp_certificate .certificate__details__text {
        padding-left: 0;
        padding-top: 1.5rem;
    }

}

@media only screen and (max-width: 29.125rem) {

    #crd_cred_design .card_view {
        min-width: unset;
        width: 100%;
    }

}