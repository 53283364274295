#crd_reports_credit_tracking_by_holder .options_group_wrapper {
    flex-direction: row;
    padding-bottom: 1.25rem;
    gap: 0.8125rem;
    flex-wrap: wrap;
}

#crd_reports_credit_tracking_by_holder .option_wrapper {
    align-self: start;
    flex-direction: column;
    margin-right: 0.5rem;
}

#crd_reports_credit_tracking_by_holder .option_label {
    align-self: start;
    flex-direction: column;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 78.125rem) {
    #crd_reports_credit_tracking_by_holder .options_group_wrapper {
        flex-direction: column; 
    }
    
    #crd_reports_credit_tracking_by_holder .option_wrapper {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

}