.assignmentcard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22.188rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
}

.assignmentcard.assignmentcard-ready {
    background: var(--color-green-700);
}

.assignmentcard.assignmentcard-notready {
    background: var(--color-red-700);
}

.assignmentcard.assignmentcard-notassigned {
    background: var(--color-primary-400);
}

.assignmentcard .assignment__details_wrapper {
    flex-direction: column;
    padding: 1rem;
    width: 20.188rem;
}

.assignmentcard .assignment__details {
    justify-content: space-between;
}

.assignmentcard .status__wrapper {
    background: var(--color-red-200);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 9.5rem;
    height: 1.5rem;
    border-radius: 6.25rem;
    margin-bottom: 1rem;
}

.assignmentcard .ready {
    color: var(--color-green-700);
}

.assignmentcard .notready {
    color: var(--color-red-700);
}

.assignmentcard .text--xl-bold {
    margin-bottom: 0.5rem;
    color: var(--color-white);
}

.assignmentcard .address__text {
    color: var(--color-white);
    text-decoration: underline;
    margin-bottom: 1.5rem;
}

.assignmentcard div.assignment__icon {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--color-white);
    margin-right: 0.25rem;
}

.assignmentcard .assignment__logo {
    margin-bottom: 1.5rem;
}

.assignmentcard .assignment__logo img {
    max-height: 3rem;
    max-width: 100%;
}

.assignmentcard .text--xl-bold,
.assignmentcard .address__text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.assignment__badge {
    justify-content: start;
}

.assignmentcard .qrbutton {
    align-self: flex-end;
    padding: 0.438rem 0.313rem 0.438rem 0.313rem;
    font-weight: 500;
    height: 1.5rem;
    border: 1px solid var(--color-primary-500);
}

.assignment__name_address {
    flex-direction: column;
    position: relative;
    max-width: calc(100% - 5.75rem);
}

.assignmentcard .message.warning {
    padding: 0 0.5rem 0 0.5rem;
    margin: 0.5rem 0 0 0;
}

.assignmentcard .message.warning p {
    margin: 0.5rem 0 0.5rem 0;
}