#terms.page {
    padding: unset;
}

#terms .credivera__header {
    padding: 1rem 2rem;
    width: auto;
    height: 4rem;
    background: #003451;
}

#terms .logo_header {
    margin-left: 1.5rem;
}

#terms .content-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: block;
}

#terms .content-wrapper {
    padding: 1.563rem 1.875rem;
    margin-top: 2.5rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
}

#terms .sub-title {
    margin-top: 0.5rem;
}

#terms .numbered {
    margin-right: 1rem;
}

#terms .sub-content {
    margin-bottom: 1rem;
}

#terms .bullet-points {
    margin-left: 2rem;
}

#terms .link {
    margin-left: 0.3rem;
}

@media only screen and (max-width: 26.25rem) {
    #terms .content-wrapper {
        margin-left: unset;
    }
}