.digitalcertificate_wrapper {
    width: 37.5rem;
    display: block;
    align-self: center;
    background-color: var(--color-white);
}

.digitalcertificate_wrapper .cmp_certificate {
    margin: 0;
}

.offline .digitalcertificate_wrapper .text--sm-regular {
    margin-top: unset;
}

.offline .digitalcertificate_wrapper .display--sm-medium {
    text-align: unset;
    justify-content: unset;
}

.offline .digitalcertificate_wrapper .preloader__wrapper {
    position: relative;
    width: 37.5rem;
    min-height: 37.5rem;
}

@media only screen and (max-width: 38.5rem) {

    .digitalcertificate_wrapper,
    .offline .digitalcertificate_wrapper .preloader__wrapper {
        width: calc(100% - 1rem);
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

}