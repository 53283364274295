.icon__error_lg {
    color: var(--color-red-600);
    align-self: center;
    width: 66.67px;
    margin-top: 1.5rem;
}

.crd__invite {
    justify-content: center;
    align-self: center;
}

.crd__invite .invite-message-text {
    text-align: left;
}

.crd__invite .invite-message-text a {
    display: inline-block;
}

.crd__invite .message .button {
    display: inline-block;
    height: fit-content;
}

@media only screen and (max-width: 31.25rem) {
    .crd__invite {
        width: 90%;
    }
}