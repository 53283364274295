
#cmp_warning {
    width: 35rem;
    min-width: unset;
    max-width: 35rem;
    height: auto;
    padding: 1.5rem;
}

#cmp_warning .warning__content {
    flex-direction: column;
    margin-left: 1rem;
}

#cmp_warning .warning__icon svg {
    width: 2.5rem;
    height: 2.5rem;
}

#cmp_warning .text--sm-regular {
    padding: 0.5rem 0 1.5rem 0;
}

/*  media queries --------------------------------------------------------------------------------------------------- */
