
#content_credential.content__wrapper {
    flex-direction: column;
    padding-top: 1rem;
}

#content_credential .content__description {
    margin-bottom: 1rem;
}

#content_credential .button__wrapper {
    margin-bottom: 1rem;
}

#content_credential #grp__required_credits,
#content_credential #grp__required_credits input {
    width: 6.5rem;
}

#content_credential .credentials__wrapper {
    min-height: unset;
}

#content_credential .credentials__wrapper .icon__upload {
    height: 2rem;
    width: 2rem;
}

#content_credential .credentials__wrapper__header {
    width: 100%;
}

#content_credential .credentials__wrapper__header div:last-of-type {
    margin-left: auto;
    margin-right: 3rem;
}

#content_credential .credentials__item {
    border-top: 1px solid var(--color-gray-300);
    padding-top: 0.75rem;
    width: 100%;
}

#content_credential .credentials__item:first-of-type {
    border-top: none;
    padding-top: 0;
}

#content_credential .credentials__item .cell__icon--right {
    flex-grow: 1;
}

#content_credential .credentials__item .credentials__item_text {
    flex-direction: column;
    max-width: 60%;
    word-break: break-word;
}

#content_credential .credentials__item .credential__item__value {
    margin-left: auto;
    margin-right: 1rem;
}

#content_credential .credentials__item .credential__item__value input {
    width: 6rem;
}

#content_credential .credentials__item.cell__icon--right.creditbased .svg {
    margin-left: unset;
}

#content_credential .ui.checkbox {
    margin-top: 1rem;
}

#content_credential .credit__based__note {
    display: block;
}

/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 31.25rem) {
    #content_credential .credentials__item .credentials__item_text {
        max-width: 45%;
    }
}