.cmp_verification_template {
    flex-direction: column;
    width: 100%;
    padding: 3rem 0;
}

.cmp_verification_template.template__modal {
    align-self: center;
}

.cmp_verification_template .template__logo {
    align-self: center;
}

.cmp_verification_template .template__logo img {
    max-width: 14.375rem;
    max-height: 5rem;
}

.cmp_verification_template .template__body {
    margin: 1.5rem;
    max-width: 20rem;
    min-width: 15rem;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 2rem 2.5rem;
    align-self: center;
}

.cmp_verification_template .template__body__name {
    max-width: 23rem;
    align-self: center;
    text-align: center;
}

.cmp_verification_template .template_description {
    margin-top: 1rem;
    align-self: center;
}

.cmp_verification_template .qr_placeholder {
    margin-top: 1.5rem;
    align-self: center;
    width: 12.5rem;
    height: 12.5rem;
}
