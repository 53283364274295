
#mdl_unlockvc {
    max-width: 28rem;
    min-width: unset;
    width: 28rem;
}

#mdl_unlockvc .content__description {
    flex-direction: column;
    margin-bottom: 1.5rem;
    max-width: 100%;
}

#mdl_unlockvc .content__description div:not(:last-child) {
    margin-bottom: 0.75rem;
}

#mdl_unlockvc .content__description div:only-child {
    margin-bottom: 0;
}

#mdl_unlockvc .modal__header {
    background: var(--color-white);
    border-bottom: none;
    border-radius: 0.375rem 0.375rem 0 0;
    flex-direction: column;
    padding-top: 1rem;
}

#mdl_unlockvc .modal__header__right {
    margin-bottom: 0.5rem;
}

#mdl_unlockvc .modal__content {
    padding-top: 0;
}

#mdl_unlockvc .detailsgroup {
    padding-top: 0;
    flex-direction: column;
    width: 100%;
}

#mdl_unlockvc .detailsgroup .field,
#mdl_unlockvc .detailsgroup input {
    width: 100%;
}

#mdl_unlockvc .tertiary {
    margin-left: 0;
    margin-top: 1.5rem;
    text-decoration: none;
}

#mdl_unlockvc .message {
    align-items: flex-start;
    margin: 0;
}



/*  MEDIA QUERIES =================================================================================================== */

@media only screen and (max-width: 31.5rem) {

    #mdl_unlockvc {
        max-width: 95%;
    }

}