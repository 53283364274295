#crd_access .tbl__permissions__legend {
    margin-top: 1rem;
    flex-wrap: wrap;
}

#crd_access .tbl__permissions__legend__item  {
    margin-right: 2.5rem;
}

#crd_access .table__headercell_text,
#crd_access tbody tr {
    cursor: unset;
}

#crd_access .cell__icon {
    width: unset;
}

#crd_access .table__headercell:not(#organization_name) .table__headercell_text {
    justify-content: center;
}

#crd_access .card__footer {
    border-top: none;
}

@media only screen and (max-width: 80.625rem) {

    #crd_access .tbl__permissions__legend__item {
        width: 100%;
        margin-right: 0;
    }

}