.credentials_attachment_list {
    list-style: none;
    padding: 0;
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px!important;
}

.credentials_attachment_list li {
    flex: 0 0 auto;
    width: 33.333333%;
    padding: 0 10px;
}

.credentials_attachment_thumb {
    border: 1px solid #ebebeb;
    background-color: #fafbfb;
    padding: 5px;
    margin: 0;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.credentials_attachment_thumb > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.credentials_attachment_list li:nth-child(n + 4) .credentials_attachment_thumb {
    margin-top: 20px;
}

.credentials_attachment_thumb img {
    vertical-align: middle;
    max-width: 100%;
	max-height:200px;
}

.credentials_attachment_thumb figcaption {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #cfd3d5;
    padding: 5px 0 0;
}

#mdl_image {
    width: auto;
    height: auto;
    overflow-x: auto;
    overflow-y: auto;
}

#attachment_pdf {
    font-size: 3em;
}

@media (max-width: 480px) {
    .credentials_attachment_list li {
        width: 100%;
        margin-bottom: 16px;
    }
}
